<template>
    
    <div class="icp">
      <!--{{`@${year} ${author}`}} {{ record }}
      <div class="icp">{{`@${year} ${author}`}} ></div>
  
    <a href="https://beian.miit.gov.cn/">湘ICP备2023008706号</a>-->
    <a href="https://beian.miit.gov.cn/">{{`@${year} ${author}`}} {{ record }}</a>
    <a href="https://beian.miit.gov.cn/publish/query/indexFirst.action/">关于备案</a>
  </div>
    
 </template>
<script setup>
let year=new Date().getFullYear();
let author='蓝剑鱼';
let record='湘ICP备2023008706号';
</script>
<style>
.icp{
  position:absolute;
  bottom:0;
  right:0;
  margin:10px 0;
  width:100%;
  height:36px;
  white-space:pre;
  text-align:center;
  color:gray;
  z-index:1000;
}
.icp >a{
  color:gray;
  text-decoration:none;
}
.icp > a:hover {
  color:aqua;
  text-decoration:none;
}
</style>